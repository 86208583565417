
@font-face {
  font-family: 'CenturyGothic';
  src: url('../Assets/fonts/CenturyGothic.ttf');
}

@font-face {
  font-family: 'CenturyGothicBold';
  src: url('../Assets/fonts/CenturyGothicBold.ttf');
}

@mixin font-family($font-name) {
  font-family: $font-name;
}

h1, h2, h3, h4, h5, h6, p, span {
  @include font-family('CenturyGothic');
  &.bold { @include font-family('CenturyGothicBold'); }
}